<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    payee: {
      name: { required },
      person_type: { required },
      person_number: { required },
      bank: { required },
      agency: { required },
      agency_digit: {},
      account: { required },
      account_digit: { required },
      account_type: { required },
    }
  },
  data() {
    return {
      payee: {
        loading: false,

        name: "",
        person_type: 1,
        person_number: "",
        bank: "",
        agency: "",
        agency_digit: "",
        account: "",
        account_digit: "",
        account_type: 1,
      }
    };
  },
  methods: {
    cadastrarFavorecido() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.payee.loading = true;

        api
          .post("cobrancas", {
            name: this.payee.name,
            person_type: this.payee.person_type,
            person_number: this.payee.person_number,
            bank: this.payee.bank,
            agency: this.payee.agency,
            agency_digit: this.payee.agency_digit,
            account: this.payee.account,
            account_digit: this.payee.account_digit,
            account_type: this.payee.account_type,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.payee.name = "";
              this.payee.person_type = "";
              this.payee.person_number = "";
              this.payee.bank = "";
              this.payee.agency = "";
              this.payee.agency_digit = "";
              this.payee.account = "";
              this.payee.account_digit = "";
              this.payee.account_type = "";
              this.payee.password = "";
              this.$v.$reset();

              this.$router.push('/boletos');
              this.$toast.success('O favorecido foi cadastrado com sucesso.');

            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
            }

            this.payee.loading = false;
          });
      }
    },
  },
  mounted() {
  },
  watch: {
    person_type: function () {
      this.person_number = "";
    },
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/cobrancas">Cobranças</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Criar boleto</li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Criar boleto</h3>
        <p>Crie um boleto para recebimento de uma cobrança.</p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-5">
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>